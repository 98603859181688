import React, { Component, useState } from "react";
import MyModal from './MyModal.js';

const Dashboard = () => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div>
			<button onClick={() => setIsOpen(true)}>
				Open Modal Two
			</button>
			{isOpen && <MyModal setIsOpen={setIsOpen} />}
		</div>
	);
};

export default Dashboard

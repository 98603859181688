

export default function Table(data) {

    console.log(data);

    const dataset = data.data;
    const entrylist = Object.keys(dataset); // Rows
    const proplist = Object.keys(dataset[entrylist[0]]); // Columns

    return ( 

        <div>
        <h2>Table from .json file</h2>
        <table>
            <thead>
                <tr>
                    {proplist.map(prop => <th>{prop}</th>)}
                </tr>
            </thead>

            <tbody>
            {entrylist.map(entry => (
                <tr>
                    {proplist.map(prop => <td>{dataset[entry][prop].toString()}</td>)}
                </tr>
            ))}
            </tbody>
        </table>
        </div>
 
    );
}
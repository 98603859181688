import React from "react";

const MyModal = ({ setIsOpen }) => {
  return (
    <>
      <div onClick={() => setIsOpen(false)} />
      <div>
        <div>
          <div>
            <h5>Dialog</h5>
          </div>
          <button onClick={() => setIsOpen(false)}>
            Close Line
          </button>
          <div>
            Are you sure you want to delete the item?
          </div>
          <div>
            <div>
              <button onClick={() => setIsOpen(false)}>
                Delete
              </button>
              <button
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyModal

import React, { useParams, useState, Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Body from '../components/Body';
import Popup from '../components/Popup';
import Dashboard from '../components/Dashboard';

import Table from '../components/Table';
import myData from '../data/TestData.json';

export default function TestPage() {
	const [show, setShow] = useState(false);
	
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
	return (
		<Body sidebar>
			<h1>Test Page</h1>
			<Button variant="primary" onClick={handleShow}>
				Launch Modal
			</Button>
			
			
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header>
				
				<Modal.Body>
					<Popup />
				</Modal.Body>
				
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close 
					</Button>
					<Button variant="primary" onClick={handleClose}>
						Fire Blaster
					</Button>
				</Modal.Footer>
			</Modal>
			
			<Dashboard />

			<Table data={myData} />
			
		</Body>

	);
}